import styled from 'styled-components';
import fonts from 'assets/global/fonts';
import colors from 'assets/global/colors';

export const StyledContainer = styled.div`
  p {
    font-family: ${fonts.secondary};
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 40px;
    padding: 0;
  }
`;

export const StyledGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

export const StyledGridItem = styled.div`
  width: 33.33%;
  text-align: center;

  h5 {
    font-family: ${fonts.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
  }

  p {
    font-family: ${fonts.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
  }
`;

export const StyledContentSubtitle = styled.h3`
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '18px')};
  line-height: 34px;
  padding-left: ${(props) =>
    props.paddingLeft ? props.paddingLeft : '0px'};
  justify-content: center;
  margin: 1.5rem 0;
  position: relative;
  padding: 10px 0;
  width: max-content;

  &:before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 130%;
    height: 1px;
    background-color: ${colors.red300};
    content: '';

    @media screen and (max-width: 980px) {
      top: 0;
      width: 110%;
    }
  }

  @media screen and (max-width: 980px) {
    padding-left: 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    border-bottom: none;
  }
`;
