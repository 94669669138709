import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4rem;

  @media screen and (max-width: 980px) {
    row-gap: 2rem;
  }

  p,
  ul,
  li {
    font-size: 18px;
    font-weight: 300;
  }

  ul {
    line-height: 2.5;
  }
`;

export const StyledHeadingTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  column-gap: 6rem;

  img {
    @media screen and (max-width: 980px) {
      display: none;
    }
  }
`;

export const StyledHeadingContent = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 2rem;
  column-gap: 4rem;

  > div {
    flex: 1;
  }

  @media screen and (max-width: 980px) {
    flex-direction: column;
  }
`;

export const StyledResultsTitle = styled.div`
  position: relative;
  margin: 0;
  padding-bottom: 10px;
  font-family: ${fonts.primary};
  font-size: 18px;
  font-weight: 600;
  color: ${colors.gray300};

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100px;
    height: 2px;
    background-color: ${colors.red400};
    content: '';
  }

  @media screen and (max-width: 980px) {
    font-size: 16px;
    font-weight: 600;
  }
`;

export const StyledHeadingResults = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
