import React from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { StyledSubtitle } from 'assets/global/styled';
import {
  StyledContainer,
  StyledItem,
  StyledItemTitle,
  StyledItemComment,
  StyledRow
} from './styled';

const GridContent = ({ title, details }) => {
  if (details.length <= 3) {
    return (
      <>
        <StyledSubtitle>{title}</StyledSubtitle>
        <StyledContainer>
          {details.map((item) => {
            return (
              <StyledItem>
                <StyledItemTitle>{item.title}</StyledItemTitle>
                <StyledItemComment>
                  <MarkdownPreview source={item.content} />
                </StyledItemComment>
              </StyledItem>
            );
          })}
        </StyledContainer>
      </>
    );
  } else {
    return (
      <>
        <StyledSubtitle>{title}</StyledSubtitle>
        <StyledContainer wrap={'wrap'}>
          <StyledRow>
            {details.map((item, index) => {
              if (index <= 1) {
                return (
                  <StyledItem>
                    <StyledItemTitle
                      fontSize={'18px'}
                      paddingLeft={'0px'}
                    >
                      {item.title}
                    </StyledItemTitle>
                    <StyledItemComment padding={'0px 0px 50px 0px'}>
                      <MarkdownPreview source={item.content} />
                    </StyledItemComment>
                  </StyledItem>
                );
              } else {
                return null;
              }
            })}
          </StyledRow>
          <StyledRow>
            {details.map((item, index) => {
              if (index > 1 && index <= 3) {
                return (
                  <StyledItem>
                    <StyledItemTitle
                      fontSize={'18px'}
                      paddingLeft={'0px'}
                    >
                      {item.title}
                    </StyledItemTitle>
                    <StyledItemComment padding={'0px 0px 50px 0px'}>
                      <MarkdownPreview source={item.content} />
                    </StyledItemComment>
                  </StyledItem>
                );
              } else {
                return null;
              }
            })}
          </StyledRow>
        </StyledContainer>
      </>
    );
  }
};

export default GridContent;
