import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import {
  StyledTestimonialsItem,
  StyledTestimonialsPartner,
  StyledTestimonialsContent
} from './styled';

SwiperCore.use([Navigation, Pagination]);

const Testimonials = ({ testimonials }) => {
  return (
    <Swiper
      slidesPerView={1}
      loop="true"
      navigation
      pagination={{ clickable: true }}
    >
      {testimonials.map((testimonial) => {
        const {
          id,
          logo,
          author,
          role,
          description,
          company,
          showOnHome
        } = testimonial;
        if (showOnHome !== false) {
          return (
            <SwiperSlide key={id}>
              <StyledTestimonialsItem>
                {logo?.url && (
                  <StyledTestimonialsPartner>
                    <img
                      src={logo?.url}
                      width="auto"
                      height="72"
                      alt={logo?.alt || company || ''}
                    />
                  </StyledTestimonialsPartner>
                )}
                {description && author && (
                  <StyledTestimonialsContent>
                    <blockquote>
                      <p>{description}</p>
                    </blockquote>
                    {author && role && (
                      <p>
                        <strong>{author} |</strong> {role}
                      </p>
                    )}
                  </StyledTestimonialsContent>
                )}
              </StyledTestimonialsItem>
            </SwiperSlide>
          );
        }

        return null;
      })}
    </Swiper>
  );
};

export default Testimonials;
