import styled from 'styled-components';

export const StyledButtonDesktop = styled.div`
  @media screen and (max-width: 980px) {
    display: none;
  }
`;

export const StyledButtonMobile = styled.div`
  @media screen and (min-width: 981px) {
    display: none;
  }
`;
