import React from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';
import {
  StyledContainer,
  StyledItem,
  StyledItemLabel
} from './styled';

const Testimonial = (props) => {
  const {
    logo,
    author,
    role,
    description,
    company,
    label,
    callToAction
  } = props;

  return (
    <>
      <StyledContainer>
        <StyledItem>
          <div>
            <img
              src={logo?.url}
              width="auto"
              height="104"
              alt={logo?.alt || company || ''}
            />
          </div>
          <StyledItemLabel>{label}</StyledItemLabel>
        </StyledItem>
        <StyledItem>
          <p>{description}</p>

          <p>
            {author} | {role}
          </p>

          <MarkdownPreview source={callToAction} />
        </StyledItem>
      </StyledContainer>
    </>
  );
};

export default Testimonial;
