import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;

  column-gap: 10em;

  @media screen and (max-width: 960px) {
    flex-direction: column;
    column-gap: 0px;
  }
`;

export const StyledItem = styled.div`
  a {
    text-decoration: none;
    color: red;
  }

  img {
    opacity: 30%;
  }

  &:nth-child(2) {
    p {
      font-family: ${fonts.secondary};
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 29px;
    }
    @media screen and (max-width: 960px) {
      margin: 50px 20px 0px 20px;
      padding: 20px;
      border: 1px solid ${colors.gray50};
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      p {
        &:nth-child(2) {
          font-weight: bold;
          font-size: 14px;
        }
      }
    }
  }
`;

export const StyledItemLabel = styled.div`
  font-family: ${fonts.secondary};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 29px;
`;
