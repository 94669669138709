import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledTestimonials = styled.section`
  display: block;
  width: 100%;
  padding: 80px 0;
`;

export const StyledTestimonialsSwiper = styled.div`
  display: block;
`;

export const StyledTestimonialsContainer = styled.div`
  display: block;
`;

export const StyledTestimonialsItem = styled.div`
  display: block;
  max-width: 60%;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const StyledTestimonialsPartner = styled.h6`
  display: block;
  text-align: center;
`;

export const StyledTestimonialsContent = styled.div`
  display: block;

  blockquote {
    margin: 0;
    padding: 0;
    font-family: ${fonts.secondary};
    font-weight: 100;
    color: ${colors.gray300};
    line-height: 1.8;
  }

  p {
    font-weight: 300;

    strong {
      font-weight: 700;
    }
  }
`;
