import React from 'react';
import {
  StyledProcessContainer,
  StyledProcessWrapper,
  StyledProcessStep,
  StyledDivider
} from './styled';
import MarkdownPreview from '@uiw/react-markdown-preview';

const Process = ({ steps, description }) => {
  return (
    <StyledProcessContainer>
      {description !== null && description.length > 0 ? (
        <MarkdownPreview source={description} />
      ) : null}

      <StyledProcessWrapper>
        {steps.map((step, index) => {
          const { title, description, icon } = step;

          return (
            <>
              <StyledProcessStep>
                <img
                  src={icon?.url}
                  width="auto"
                  height="80"
                  alt={icon?.alt || title || ''}
                />

                <h4>{title}</h4>

                <MarkdownPreview source={description} />
              </StyledProcessStep>

              {index === steps.length - 1 ? null : <StyledDivider />}
            </>
          );
        })}
      </StyledProcessWrapper>
    </StyledProcessContainer>
  );
};

export default Process;
