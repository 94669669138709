import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: ${(props) => (props.wrap ? props.wrap : 'nowrap')};
  justify-content: space-between;
  column-gap: 70px;

  @media screen and (max-width: 980px) {
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  column-gap: 70px;

  @media screen and (max-width: 980px) {
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

export const StyledItem = styled.div`
  width: 100%;
`;

export const StyledItemTitle = styled.div`
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '18px')};
  line-height: 34px;
  padding-left: ${(props) =>
    props.paddingLeft ? props.paddingLeft : '0px'};
  justify-content: center;
  margin-bottom: 5px;
  position: relative;
  padding: 10px 0;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 2px;
    background-color: ${colors.red300};
    content: '';
  }

  @media screen and (max-width: 980px) {
    padding-left: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    border-bottom: none;

    &:before {
      display: none;
    }
  }
`;

export const StyledItemComment = styled.div`
  padding: ${(props) =>
    props.padding ? props.padding : '0px 0px 25px 0px'};

  p {
    font-family: ${fonts.primary};
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    font-size: 18px;
  }

  @media screen and (max-width: 980px) {
    position: relative;
    font-family: ${fonts.primary};
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 120%;
    padding: 20px 0px;
    line-height: 34px;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100px;
      height: 2px;
      background-color: ${colors.red300};
      content: '';
    }
  }
`;
