import React from 'react';
import OtherServices from 'components/OtherServices';
import { Link } from 'gatsby';
import Button from 'components/Form/Button';
import { StyledButtonDesktop, StyledButtonMobile } from './styled';

const NextSteps = (props) => {
  const { ctaText, ctaTextAlt, ctaLink, ...servicesProps } = props;
  return (
    <>
      <OtherServices services={[{ ...servicesProps }]}>
        <StyledButtonDesktop>
          <Button
            style={{ justifyContent: 'center' }}
            to={ctaLink}
            as={Link}
            type="button"
            callToAction={true}
            withIcon={true}
            width={'max-content'}
          >
            {ctaText}
          </Button>
        </StyledButtonDesktop>

        <StyledButtonMobile>
          <Button
            style={{ justifyContent: 'center' }}
            variant="dark"
            to={ctaLink}
            as={Link}
            type="button"
            callToAction={true}
            withIcon={true}
            width={'max-content'}
          >
            {ctaTextAlt}
          </Button>
        </StyledButtonMobile>
      </OtherServices>
    </>
  );
};

export default NextSteps;
