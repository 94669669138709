import React from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { StyledDashedTitle } from 'assets/global/styled';
import {
  StyledHeadingWrapper,
  StyledHeadingTitleContainer,
  StyledHeadingContent,
  StyledHeadingResults,
  StyledResultsTitle
} from './styled';

const Heading = ({ serviceDetail }) => {
  const {
    title,
    description,
    coverImage,
    content1,
    content2Title,
    content2
  } = serviceDetail;

  return (
    <StyledHeadingWrapper>
      <StyledHeadingTitleContainer>
        <div>
          <StyledDashedTitle>{title}</StyledDashedTitle>
          <MarkdownPreview source={description} />
        </div>

        <img
          src={coverImage?.url}
          width="auto"
          height="280"
          alt={coverImage.alt}
        />
      </StyledHeadingTitleContainer>

      {content1 !== null && content1.length > 0 ? (
        <StyledHeadingContent>
          <MarkdownPreview source={content1} />

          <StyledHeadingResults>
            <StyledResultsTitle>{content2Title}</StyledResultsTitle>
            <MarkdownPreview source={content2} />
          </StyledHeadingResults>
        </StyledHeadingContent>
      ) : null}
    </StyledHeadingWrapper>
  );
};

export default Heading;
