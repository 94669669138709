import React from 'react';
import Helmet from 'react-helmet';
import { StyledSection } from 'templates/Services/styled';
import { StyledWrapper, StyledSubtitle } from 'assets/global/styled';
import ContactUs from 'components/ContactUs';
import GridContent from 'components/GridContent';
import Testimonials from 'components/Testimonials/index.js';
import ContactUsCTA from 'components/ContactUsCTA';
import Technologies from 'components/Technologies';
import Awards from 'components/Awards';
import Heading from './Heading';
import NextSteps from './NextSteps';
import Process from './Process';
import RelevantNumbers from 'components/RelevantNumbers';
import Services from 'components/Services';
import Testimonial from 'components/Testimonial';
import Method from 'components/Method';
import Cards from './Cards';
import Packages from 'components/Packages';

const StyledContainer = (props) => {
  const { children, ...otherProps } = props;

  return (
    <StyledSection {...otherProps}>
      <StyledWrapper>{children}</StyledWrapper>
    </StyledSection>
  );
};

const ServiceSingle = ({
  serviceDetail,
  pageContext,
  testimonials,
  singleTestimonials,
  awards,
  numbers,
  technologies,
  services,
  packages
}) => {
  const {
    contactForm,
    locale,
    content6Title,
    content3,
    serviceDetailV23Steps,
    serviceDetailV23Processes,
    content5Title,
    callToAction1Title,
    callToAction1Button,
    content4Title,
    content4,
    content4Icon,
    callToAction3Button,
    callToAction3ButtonAlt,
    callToAction3Href,
    content7Title,
    content8Title,
    content8Description,
    content9Title,
    content10Title,
    content11Title,
    content12Title,
    content12Subtitle,
    content12,
    content12Icons,
    content12Items,
    content13Title,
    content13Subtitle,
    showIa,
    cardsTitle,
    cardsContent,
    cards,
    hasTechnologiesSection,
    hasRelevantNumbersSection,
    hasSingleTestimonialSection,
    hasTestimonialsSection
  } = serviceDetail;

  const { baseURL, slugService } = pageContext;

  return (
    <React.Fragment>
      <StyledContainer>
        <Heading serviceDetail={serviceDetail} />
      </StyledContainer>

      <ContactUsCTA
        title={callToAction1Title}
        pageContext={pageContext}
        buttonText={callToAction1Button}
        little
      />

      {content12Items.length > 0 ? (
        <StyledContainer>
          <Method
            title={content12Title}
            subtitle={content12Subtitle}
            description={content12}
            images={content12Icons}
            itemDetails={content12Items}
          />
        </StyledContainer>
      ) : null}

      {serviceDetailV23Processes.length > 0 ? (
        <StyledContainer>
          <StyledSubtitle>{content8Title}</StyledSubtitle>
          <Process
            steps={serviceDetailV23Processes}
            description={content8Description}
          />
        </StyledContainer>
      ) : null}

      {serviceDetailV23Steps.length > 0 ? (
        <StyledContainer>
          <GridContent
            title={content3}
            details={serviceDetailV23Steps}
          />
        </StyledContainer>
      ) : null}

      {hasTechnologiesSection ? (
        <StyledContainer>
          <Technologies
            contentSubtitle={content7Title}
            technologies={technologies}
            sorted
          />
        </StyledContainer>
      ) : null}

      {showIa ? (
        <StyledContainer>
          <Packages
            page={{
              content5Subtitle: content13Title,
              content5: content13Subtitle
            }}
            packages={packages}
          />
        </StyledContainer>
      ) : null}

      {cards !== null ? (
        <StyledContainer>
          <Cards
            title={cardsTitle}
            content={cardsContent}
            cards={cards}
          />
        </StyledContainer>
      ) : null}

      {hasRelevantNumbersSection ? (
        <StyledContainer>
          <RelevantNumbers
            contentSubtitle={content10Title}
            numbers={numbers}
          />
        </StyledContainer>
      ) : null}

      {hasSingleTestimonialSection
        ? singleTestimonials.map((testimonial) => {
            return (
              <StyledContainer>
                <StyledSubtitle>{content11Title}</StyledSubtitle>
                <Testimonial
                  logo={testimonial.logo}
                  author={testimonial.author}
                  role={testimonial.role}
                  description={testimonial.description}
                  company={testimonial.company}
                  label={testimonial.label}
                  callToAction={testimonial.callToAction}
                />
              </StyledContainer>
            );
          })
        : null}

      {awards.length > 0 ? (
        <Awards awards={awards} title={content5Title} light />
      ) : null}

      {hasTestimonialsSection ? (
        <StyledContainer>
          <StyledSubtitle>{content6Title}</StyledSubtitle>
          <Testimonials testimonials={testimonials} />
        </StyledContainer>
      ) : null}

      <ContactUs
        contactForm={contactForm}
        baseURL={baseURL}
        lang={locale}
        styledAsLp={true}
      />

      {content4Title !== null && content4Title.length > 0 ? (
        <StyledContainer>
          <NextSteps
            title={content4Title}
            description={content4}
            altIcon={content4Icon}
            ctaText={callToAction3Button}
            ctaTextAlt={callToAction3ButtonAlt}
            ctaLink={`../${callToAction3Href}`}
          />
        </StyledContainer>
      ) : services.length > 0 ? (
        <StyledContainer>
          <Services
            contentSubtitle={content9Title}
            baseURL={baseURL}
            services={services}
            pageContext={pageContext}
          />
        </StyledContainer>
      ) : null}

      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context":"https://schema.org",
              "@type":"Organization",
              "name":"ateliware",
              "legalName":"ateliware",
              "url":"http://ateliware.com/br/services",
              "logo":"https://media.graphassets.com/gJsd71c5SGh1RyV8tIrV",
              "address":{
                "@type":"PostalAddress",
                "streetAddress":"Rua Pasteur, 463",
                "addressLocality":"Curitiba",
                "addressRegion":"Paraná",
                "postalCode":"80250-080",
                "addressCountry":"Brasil"
              },
              "contactPoint":{
                "@type":"ContactPoint",
                "contactType":"Contato",
                "telephone":"[+55 41 3010-2275]",
                "email":"hello@ateliware.com"
              },
              "sameAs":[
                "https://github.com/ateliware",
                "https://www.linkedin.com/company/ateliware/",
                "https://twitter.com/ateliware",
                "https://www.youtube.com/channel/UCxPjijyar2emdKLPpOrugOw",
                "https://www.meetup.com/ateliware/",
                "https://goo.gl/maps/gLPc35mF3js7rLnL8"
              ]
            }
          `}
        </script>
      </Helmet>
    </React.Fragment>
  );
};

export default ServiceSingle;
