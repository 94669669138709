import React from 'react';
import { graphql } from 'gatsby';
import Header from 'components/Header';
import Footer from 'components/Footer';
import SEO from 'components/SEO';
import Single from './Single/index';

const SinglePage = ({
  data: {
    ateliware: {
      servicesV23,
      serviceDetailV23,
      headerMenu,
      footerMenu,
      testimonials,
      awards,
      numbers,
      technologies,
      singleTestimonials,
      packages
    }
  },
  pageContext
}) => {
  const { seo } = serviceDetailV23;
  const { locale, slugService } = pageContext;

  return (
    <React.Fragment>
      {(() => {
        switch (slugService) {
          case 'discovery':
            return (
              <SEO
                meta_title={
                  locale === 'pt'
                    ? seo?.metaTitle
                    : 'discovery | market insights and new opportunities'
                }
                meta_description={
                  locale === 'pt'
                    ? seo?.metaDescription
                    : 'To build a digital product we need to research the market and validate the investment. We are ready to help you in this step.'
                }
                og_title={
                  locale === 'pt'
                    ? seo?.ogTitle
                    : 'discovery | market insights and new opportunities'
                }
                og_description={
                  locale === 'pt'
                    ? seo?.ogDescription
                    : 'To build a digital product we need to research the market and validate the investment. We are ready to help you in this step.'
                }
                og_type={seo?.ogType}
                og_image={seo?.ogImage?.url}
                lang={locale}
              />
            );
          case 'product-concept':
            return (
              <SEO
                meta_title={
                  locale === 'pt'
                    ? seo?.metaTitle
                    : 'product concept | design as an ally of your product'
                }
                meta_description={
                  locale === 'pt'
                    ? seo?.metaDescription
                    : 'Design is an ally for the success of a digital product in the acquisition and retention of users. In the product concept we will define, structure, prototype and test ideas, and then build the screens.'
                }
                og_title={
                  locale === 'pt'
                    ? seo?.ogTitle
                    : 'product concept | design as an ally of your product'
                }
                og_description={
                  locale === 'pt'
                    ? seo?.ogDescription
                    : 'Design is an ally for the success of a digital product in the acquisition and retention of users. In the product concept we will define, structure, prototype and test ideas, and then build the screens.'
                }
                og_type={seo?.ogType}
                og_image={seo?.ogImage?.url}
                lang={locale}
              />
            );
          case 'build':
            return (
              <SEO
                meta_title={
                  locale === 'pt'
                    ? seo?.metaTitle
                    : 'build | we build digital products and solutions'
                }
                meta_description={
                  locale === 'pt'
                    ? seo?.metaDescription
                    : 'Having a digital product or proprietary technology is a huge differentiator for your business growth. At build we offer web and mobile development, APIs and system integrations.'
                }
                og_title={
                  locale === 'pt'
                    ? seo?.ogTitle
                    : 'build | we build digital products and solutions'
                }
                og_description={
                  locale === 'pt'
                    ? seo?.ogDescription
                    : 'Having a digital product or proprietary technology is a huge differentiator for your business growth. At build we offer web and mobile development, APIs and system integrations.'
                }
                og_type={seo?.ogType}
                og_image={seo?.ogImage?.url}
                lang={locale}
              />
            );
          default:
            return (
              <SEO
                meta_title={seo?.metaTitle}
                meta_description={seo?.metaDescription}
                og_title={seo?.ogTitle}
                og_description={seo?.ogDescription}
                og_type={seo?.ogType}
                og_image={seo?.ogImage?.url}
                lang={locale}
              />
            );
        }
      })()}

      <Header menu={headerMenu} pageContext={pageContext} />
      <Single
        serviceDetail={serviceDetailV23}
        pageContext={pageContext}
        testimonials={testimonials}
        singleTestimonials={singleTestimonials}
        awards={awards}
        numbers={numbers}
        technologies={technologies}
        services={servicesV23}
        packages={packages}
      />
      <Footer
        footerMenu={footerMenu}
        pageContext={pageContext}
        privatePolicyTitle={
          serviceDetailV23.contactForm.privacyPolicyTitle
        }
      />
    </React.Fragment>
  );
};

export const serviceQuery = graphql`
  query ServiceV23SingleQuery(
    $idService: ID!
    $locale: [GCMS_Locale!]!
    $pageType: [GCMS_PageType!]!
  ) {
    ateliware {
      technologies(locales: $locale) {
        name
        image(locales: pt) {
          alt
          url
        }
        technologyType
      }
      packages(locales: $locale) {
        title
        subtitle
        description
        url
      }
      serviceDetailV23(where: { id: $idService }, locales: $locale) {
        id
        seo {
          metaTitle
          metaDescription
          ogTitle
          ogDescription
          ogType
          ogImage {
            url
          }
        }
        locale
        title
        description
        slug
        cardsTitle
        cardsContent
        cards
        content1
        content2Title
        content2
        content6Title
        content5Title
        content7Title
        content8Title
        content8Description
        content9Title
        content10Title
        content11Title
        content12Title
        content12Subtitle
        content12
        content12Icons(locales: pt) {
          handle
          width
          height
          id
          url
          alt
        }
        content12Items
        content13Title
        content13Subtitle
        showIa
        callToAction1Title
        callToAction1Button
        callToAction2Button
        content4Title
        content4
        content4Icon(locales: pt) {
          handle
          width
          height
          id
          url
          alt
        }
        content6Title
        callToAction1Title
        callToAction1Button
        callToAction3Button
        callToAction3ButtonAlt
        callToAction3Href
        coverImage(locales: pt) {
          handle
          width
          height
          id
          url
          alt
        }
        content3
        hasTechnologiesSection
        hasRelevantNumbersSection
        hasSingleTestimonialSection
        hasTestimonialsSection
        serviceDetailV23Steps(locales: $locale) {
          title
          content
        }
        serviceDetailV23Processes(locales: $locale) {
          title
          description
          icon(locales: pt) {
            handle
            width
            height
            id
            url
            alt
          }
        }
        contactForm {
          title
          content
          nameField
          surnameField
          companyField
          emailField
          phoneField
          serviceOptionDefault
          serviceOption1
          serviceOption2
          serviceOption3
          serviceOption4
          aboutYouField
          budgetDefault
          budgetOption1
          budgetOption2
          budgetOption3
          sendButtonText
          formErrorMessage
          privacyPolicyTitle
          privacyPolicyText
          thankYouTitle
          thankYouContent
          backToSiteText
          toBlogText
        }
      }
      numbers(locales: $locale) {
        title
        description
      }
      servicesV23(
        where: { showInPage_contains_some: $pageType }
        locales: $locale
      ) {
        altIcon {
          url
        }
        altCallToAction
        altDescription
        title
        slug
        alternativeSlug
      }
      awards(where: { showOnPage_contains_some: $pageType }) {
        image(locales: pt) {
          alt
          url
        }
      }
      singleTestimonials: testimonials(
        where: { asSingle: true }
        locales: $locale
      ) {
        id
        logo(locales: pt) {
          handle
          width
          height
          alt
          url
          fileName
          title
        }
        author
        role
        description
        company
        showOnHome
        label
        callToAction
        asSingle
      }
      testimonials(locales: $locale) {
        id
        logo(locales: pt) {
          handle
          width
          height
          alt
          url
          fileName
          title
        }
        author
        role
        description
        company
        showOnHome
        label
        callToAction
        asSingle
      }
      headerMenu: menu(where: { title: "header" }, locales: $locale) {
        locale
        callToAction
        menuItems {
          title
          slug
          locale
          goTo
          externalLink
          subMenuItems {
            title
            slug
            locale
            goTo
            externalLink
          }
        }
      }
      footerMenu: menu(where: { title: "footer" }, locales: $locale) {
        locale
        callToAction
        menuItems {
          title
          slug
          locale
          goTo
          externalLink
        }
      }
    }
  }
`;

export default SinglePage;
