import styled from 'styled-components';
import colors from 'assets/global/colors';

export const StyledProcessContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;

export const StyledProcessWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem 5rem;
  justify-content: space-between;
  align-items: start;

  @media screen and (max-width: 980px) {
    flex-direction: column;
  }
`;

export const StyledProcessStep = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 1rem;
  width: 100%;

  h4 {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
  }

  p {
    a {
      color: ${colors.red300};
      text-decoration: underline;
    }
  }
`;

export const StyledDivider = styled.span`
  border: 1px solid ${colors.gray50};
  width: 80%;
  align-self: center;

  @media screen and (min-width: 981px) {
    display: none;
  }
`;
