import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledCardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-top: 2rem;

  @media screen and (max-width: 980px) {
    flex-direction: column;
  }
`;

export const StyledCard = styled.div`
  flex-grow: 1;
  width: 100%;
  border: 1px solid ${colors.gray50};
  border-radius: 0.5rem;
  margin: 0 auto;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1);
`;

export const StyledCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1rem;
  border-bottom: 1px solid ${colors.gray50};

  span {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;

    @media screen and (max-width: 980px) {
      font-size: 18px;
    }
  }
`;

export const StyledCardBody = styled.div`
  padding: 1rem;
  color: ${colors.gray300};
  font-size: 18px;
  margin: 0;

  @media screen and (max-width: 980px) {
    font-size: 14px;
  }

  p {
    text-align: center;
  }
`;
