import React from 'react';
import { StyledSubtitle } from 'assets/global/styled';
import MarkdownPreview from '@uiw/react-markdown-preview';
import {
  StyledCardsWrapper,
  StyledCard,
  StyledCardHeader,
  StyledCardBody
} from './styled';

const Cards = ({ title, content, cards }) => {
  return (
    <>
      <StyledSubtitle light>{title}</StyledSubtitle>
      <MarkdownPreview source={content} />

      <StyledCardsWrapper>
        {cards.map((c) => {
          return (
            <StyledCard>
              <StyledCardHeader>
                <span>{c.title}</span>
              </StyledCardHeader>

              <StyledCardBody>
                <MarkdownPreview source={c.description} />
              </StyledCardBody>
            </StyledCard>
          );
        })}
      </StyledCardsWrapper>
    </>
  );
};

export default Cards;
