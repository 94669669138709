import React from 'react';
import { StyledSubtitle } from 'assets/global/styled';
import {
  StyledContainer,
  StyledGrid,
  StyledGridItem,
  StyledContentSubtitle
} from './styled';

const Method = ({
  title,
  description,
  subtitle,
  images,
  itemDetails
}) => {
  return (
    <StyledContainer>
      <StyledSubtitle>{title}</StyledSubtitle>

      <p>{description}</p>

      <StyledContentSubtitle>{subtitle}</StyledContentSubtitle>

      <StyledGrid>
        {itemDetails
          .filter((value, index) => index <= 2)
          .map((item, index) => {
            return (
              <StyledGridItem>
                <img src={images[index].url} alt={images[index].id} />
                <h5>{`${index + 1} - ${item.title}`}</h5>
                <p>{item.description}</p>
              </StyledGridItem>
            );
          })}
      </StyledGrid>
      <StyledGrid>
        {itemDetails
          .filter((value, index) => index > 2 && index <= 5)
          .map((item, index) => {
            index += 3;
            return (
              <StyledGridItem>
                <img src={images[index].url} alt={images[index].id} />
                <h5>{`${index + 1} - ${item.title}`}</h5>
                <p>{item.description}</p>
              </StyledGridItem>
            );
          })}
      </StyledGrid>
      <StyledGrid>
        {itemDetails
          .filter((value, index) => index > 5 && index <= 9)
          .map((item, index) => {
            index += 6;
            return (
              <StyledGridItem>
                <img src={images[index].url} alt={images[index].id} />
                <h5>{`${index + 1} - ${item.title}`}</h5>
                <p>{item.description}</p>
              </StyledGridItem>
            );
          })}
      </StyledGrid>
    </StyledContainer>
  );
};

export default Method;
